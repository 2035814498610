<template>
  <div class="px-6 py-2 flex flex-col">
    <header class="py-2 text-lg flex text-text-alternate-1 pr-6 font-semibold">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Personalisation</h2>
    </header>

    <BPageLoader
      v-if="preferenceResponse.isLoading || categoryResponse.isLoading"
      class="flex-grow"
    />

    <template v-else>
      <p class="py-2 text-sm text-text-alternate-7">
        Choose categories you want to follow. You can always change this later
        anytime.
      </p>
      <form @submit.prevent="handleSubmit">
        <ul class="my-4 flex flex-wrap gap-2">
          <li v-for="{ id, categoryname } in categoryResponse.data" :key="id">
            <label
              class="
                bg-bg-alternate-3
                flex
                rounded-full
                p-3
                text-text-alternate-10
                font-bold
                text-sm
                items-center
              "
            >
              <span class="px-1">{{ categoryname }}</span>
              <CheckBoxIcon
                class="mx-1 h-6 fill-current"
                :style="{ fill: checkSelection(id) ? '#567286' : '#BFCFDA' }"
              />
              <input
                type="checkbox"
                name="Music"
                :value="id"
                hidden
                v-model="categories"
              />
            </label>
          </li>
        </ul>
        <div class="text-center">
          <button
            class="
              bg-btn-bg-primary
              text-btn-text-primary text-sm
              rounded
              w-full
              mt-8
              py-4
              font-bold
            "
            type="submit"
            :disabled="!isFilled || isLoading"
          >
            <BButtonLoader class="h-4 w-4 mx-auto" v-if="isLoading" />
            <span v-else>Save</span>
          </button>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import { toRefs, watch, computed } from 'vue';
import {
  updateUserPreference,
  getCategories,
  getUserPreference,
} from '@/services/api';
import { useApi } from '@/cmp-functions/useApi';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

const sortCategories = (pre, { data }) => {
  if (!data?.data) return pre;
  return data.data.sort((a, b) => a.categoryname.localeCompare(b.categoryname));
};

export default {
  name: 'Personalisation',

  setup() {
    const store = useStore();
    const toast = useToast();
    const authUser = store.state.auth.authData;

    const [response, setUserCategory] = useApi(updateUserPreference);

    const [categoryResponse, fetchCategories] = useApi(
      getCategories,
      null,
      sortCategories
    );

    const [preferenceResponse, getPreference] = useApi(getUserPreference);
    getPreference(authUser.id);
    fetchCategories();

    watch(response, (newResponse) => {
      const { isSuccess } = newResponse;
      if (isSuccess) {
        toast.success('Updated successfully');
      }
    });

    const categories = computed({
      get: () => {
        if (!preferenceResponse.data?.categoryids) return [];
        return preferenceResponse.data?.categoryids
          .split(',')
          .map((v) => parseInt(v));
      },
      set: (value) => (preferenceResponse.data.categoryids = value.join(',')),
    });

    const checkSelection = (value) => categories.value.includes(value);

    const isFilled = computed(() => categories.value.length > 0);

    const handleSubmit = () => {
      if (isFilled.value) {
        const { id, categoryids } = preferenceResponse.data;
        setUserCategory({
          userId: authUser.id,
          categoryids,
          preferenceId: id,
        });
      }
    };

    return {
      ...toRefs(response),
      preferenceResponse,
      categoryResponse,
      authUser,
      isFilled,
      categories,
      handleSubmit,
      checkSelection,
    };
  },
};
</script>
